import React, { useEffect, createRef } from "react"
import LayoutTeam from "../components/layoutteam"
import Navigation from "../components/navigation"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import GofloaterSearchForm from "../components/search/searchalter"
import "../styles/hybrid.scss"
import SEOHeader from "../components/seo-header"
import teamLogo from "../img/TeamLogo.svg"
import lottie from "lottie-web"
import SafeSpace from "../img/safety-badge.jpg"
import animation from "../animations/newhomeanimation.json"
import "../styles/coworking.scss"

import { Typeahead } from "react-bootstrap-typeahead"
export default function MeetingSpaces() {
  // const cityImage = [
  //   "bengaluru": ["https://cdn.app.gofloaters.com/images%2F1571484315853_8%20Seater%20Meeting%20Room%20in%20MG%20Road%204.JPG?alt=media&token=586512e9-23e1-492e-9ae2-33da4acbd30f"],

  //   {
  //     chennai:
  //       "https://cdn.app.gofloaters.com/images%2F8%20Seater%20Conference%20Room%20%7C%20S1_1651576770092?alt=media&token=59a7ab90-2f06-435c-a60e-23ba31dd4a98",
  //   },
  //   {
  //     mumbai:
  //       "https://cdn.app.gofloaters.com/images%2F4%20Seater%20Meeting%20Room%20%7C%20Andheri%20East_1705491808796?alt=media&token=c249f0cc-b57b-46f9-874f-c1867184aa70",
  //   },
  // ]

  const data = useStaticQuery(graphql`
    query PartnerNewQuery {
      allCities {
        totalCount
        edges {
          node {
            city
            cityPriority
            displayCity
            slug
          }
        }
      }
      allListings(
        filter: { operatorName: { eq: "WeWork" }, slug: { ne: "" } }
        sort: { fields: isFeatured, order: DESC }
      ) {
        totalCount
        edges {
          node {
            monthPassAvailable
            dayPassAvailable
            hourPassAvailable
            officeSpaceType
            purposesList
            spaceAddress
            spaceGFName
            OriginalName
            spaceCity
            spaceId
            spaceImage
            spaceTitle
            spaceDesc
            spaceType
            subType
            priceDay
            priceHr
            priceMonth
            spaceDisplayName
            Facility
            slug
            hasCovidSafeBadge
            online
            Rating
            pincode
            bookingcount
            isFeatured
            priceperdayPreDiscount
            priceperhrPreDiscount
          }
        }
      }
    }
  `)
  const cities = data
  const citiesDisplay = data.allCities.edges.map(city => city.node.city)

  let animationContainer = createRef()
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    return () => {
      animationContainer.current = animationContainer.current || {}
      anim.destroy(animationContainer.current)
    } // optional clean up for unmounting
  }, [animationContainer])

  const CityImage = props => {
    const imageBucket = {
      coworkingPhotos: [],
      meetingRoomPhotos: [],
    }
    const getPhotoUrlByCity = cityName => {
      const photoObject = imageBucket.meetingRoomPhotos.find(
        photo => photo.city.toLowerCase() === cityName.toLowerCase()
      )

      return photoObject ? photoObject.photo : null
    }

    // Example usage:

    const photoUrl = getPhotoUrlByCity(
      props.name.toLowerCase().replace(" ", "-")
    )
    const localitynew = cities.allListings.edges.filter(
      list =>
        list.node.spaceCity === props.name &&
        list.node.spaceType === props.spaceType
    )

    if (localitynew.length > 0) {
      return (
        <div className="space_img" title={props.name}>
          <img
            src={localitynew[0].node.spaceImage.replace(
              "https://firebasestorage.googleapis.com/v0/b/gofloaters.appspot.com/o",
              "https://cdn.app.gofloaters.com"
            )}
            alt={props.name}
            title={props.name}
          />
        </div>
      )
    }
    return ""
  }

  const citySelected = city => {
    if (city !== "") {
      const cityNew = cities.allCities.edges.filter(
        list => list.node.city === city[0]
      )

      const citySlug = cityNew[0]?.node.slug
      if (citySlug) {
        navigate("/meeting-spaces/" + citySlug + "/all")
      }
    }
  }
  return (
    <div>
      <SEOHeader
        title="WeWork - GoFloaters"
        description="WeWork"
        socialURL="https://assets.gofloaters.com/socialimage/meeting-rooms.jpg"
        pinterest="true"
      ></SEOHeader>

      <LayoutTeam>
        <div className="meetingSpaces">
          <div>
            <Navigation />
            <div className="container">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <h1
                    style={{
                      fontSize: "3em",
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: "35px 0 20px ",
                    }}
                  >
                    WeWork
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row teamPadding">
            <div className="col-md-12 teamFormWorkSpace">
              <h2>WeWork Workspace</h2>
              <p style={{ paddingTop: "5px" }}>
                Workspace made simple. Whatever your budget or need, we make
                finding the perfect workspace easy.
                <br />
              </p>
              <br />
            </div>
            <div className="col-md-12">
              <div className="row">
                <br></br>
                <div className="col-md-3 TeamsSellingPoint">
                  <div className="points">
                    <div className="row">
                      <div className="col-md-3 col-2 text-center">
                        <img
                          data-src="https://assets.gofloaters.com/teams/1200spaces.svg"
                          alt="1200 Spaces"
                          className="lazyload"
                        ></img>
                      </div>
                      <div className="col-md-9 col-10">
                        <h4>2100+</h4>
                        <p>Vetted Workspaces</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 TeamsSellingPoint">
                  <div className="points">
                    <div className="row">
                      <div className="col-md-3 col-2 text-center">
                        <img
                          data-src="https://assets.gofloaters.com/teams/500booking.svg"
                          alt="500 Booking"
                          className="lazyload"
                        ></img>
                      </div>
                      <div className="col-md-9 col-10">
                        <h4> &#62; 1000</h4>
                        <p>Bookings per day</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 TeamsSellingPoint">
                  <div className="points">
                    <div className="row">
                      <div className="col-md-3 col-2 text-center">
                        <img
                          data-src="https://assets.gofloaters.com/teams/14cities.svg"
                          alt="40+ Cities"
                          className="lazyload"
                        ></img>
                      </div>
                      <div className="col-md-9 col-10">
                        <h4>40+</h4>
                        <p>Cities in India</p>
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div className="col-md-3 TeamsSellingPoint">
                  <div className="points">
                    <div className="row">
                      <div className="col-md-3 col-2 text-center">
                        <img
                          data-src="https://assets.gofloaters.com/teams/15000customers.svg"
                          alt="30000 Customers"
                          className="lazyload"
                        ></img>
                      </div>
                      <div className="col-md-9 col-10">
                        <h4>30,000+</h4>
                        <p>Customers</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <br></br>
          <div className="row">
            <div className="col-md-12">
              <h2
                style={{
                  padding: "20px 0 0px",
                  fontWeight: "bold",
                  color: "#303030",
                  lineHeight: "1",
                  fontSize: "32px",
                }}
              >
                WeWork Meeting Spaces
              </h2>
            </div>
          </div>
          <div className="row">
            {cities.allCities.edges.map(city => {
              const localitynew = cities.allListings.edges.filter(
                list =>
                  list.node.spaceCity === city.node.city &&
                  list.node.spaceType === "Conference Room"
              )
              if (localitynew.length > 0)
                return (
                  <div className="col-md-4 col-6">
                    <Link
                      to={
                        "/meeting-spaces/" +
                        city.node.slug +
                        "/all/?operatorName=WeWork"
                      }
                    >
                      <CityImage
                        name={city.node.city}
                        spaceType="Conference Room"
                      ></CityImage>
                      <h3
                        style={{
                          padding: "20px 0 0px",
                          fontWeight: "bold",
                          color: "#303030",
                          lineHeight: "1",
                          fontSize: "27px",
                        }}
                      >
                        {city.node.city}
                      </h3>
                      <h4
                        style={{
                          fontWeight: "900",
                          color: "#303030",
                          fontSize: "16px",
                        }}
                      >
                        {localitynew.length} options
                      </h4>
                      <br />
                    </Link>
                  </div>
                )
            })}
          </div>
        </div>

        <div className="container">
          <br></br>
          <div className="row">
            <div className="col-md-12">
              <h2
                style={{
                  padding: "20px 0 0px",
                  fontWeight: "bold",
                  color: "#303030",
                  lineHeight: "1",
                  fontSize: "32px",
                }}
              >
                WeWork Coworking Spaces
              </h2>
            </div>
          </div>
          <div className="row">
            {cities.allCities.edges.map(city => {
              const localitynew = cities.allListings.edges.filter(
                list =>
                  list.node.spaceCity === city.node.city &&
                  list.node.spaceType === "Shared Workspace" &&
                  list.node.dayPassAvailable === true
              )
              if (localitynew.length > 0)
                return (
                  <div className="col-md-4 col-6">
                    <Link
                      to={
                        "/coworking-spaces/" +
                        city.node.slug +
                        "/all/?operatorName=WeWork"
                      }
                    >
                      <CityImage
                        name={city.node.city}
                        spaceType="Shared Workspace"
                      ></CityImage>
                      <h3
                        style={{
                          padding: "20px 0 0px",
                          fontWeight: "bold",
                          color: "#303030",
                          lineHeight: "1",
                          fontSize: "27px",
                        }}
                      >
                        {city.node.city}
                      </h3>
                      <h4
                        style={{
                          fontWeight: "900",
                          color: "#303030",
                          fontSize: "16px",
                        }}
                      >
                        {localitynew.length} options
                      </h4>
                      <br />
                    </Link>
                  </div>
                )
            })}
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3>Get more at WeWork </h3>
              <p>
                There's more to work than sitting at a desk all day. Our
                buildings feature some of the most unique amenities designed to
                give your teams everything they need to bring their best selves
                to work every day.
              </p>
            </div>
          </div>
        </div>
      </LayoutTeam>
    </div>
  )
}
